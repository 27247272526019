<template>
  <div class="c-new-individual">
    <div class="c-new-individual__title">
      <h1 class="c-new-individual__title-text">Start trading cryptocurrencies</h1>
      <h2 class="c-new-individual__title-subtitle">with Sparrow</h2>
    </div>
    <div class="c-new-individual__description">
      <p class="c-new-individual__description-text">Sparrow offers a self-service platform for individuals to buy cryptocurrencies in Singapore and engage in options trading. Users may access and transact cryptocurrencies on a safe and secure platform that aims to meet the highest compliance and operational standards.</p>
    </div>
    <div class="c-new-individual__body">
      <div class="c-new-individual__body-title">
        Sparrow design options trading more accessible for users with customized tools.
      </div>
      <div class="c-new-individual__body-text">
        You may perform the following on the Sparrow’s Self Service Trading Platform :
      </div>
      <div class="c-new-individual__body-list">
        <ul class="c-new-individual__body-list-item">
          <li>E-OTC spot trading</li>
          <li>BTC and ETH</li>
          <li>Trade Options</li>
          <li>Trading APIs</li>
        </ul>
      </div>
      <div>
        <img src="@/assets/images/new-indi-1.svg" class="c-new-individual__body-image">
      </div>
      <div>
        <img src="@/assets/images/new-indi-2.svg" class="c-new-individual__body-image">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Individual',
    data() {
      return {

      }
    },
  }
</script>
