<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_catch">
      <Sidebar :status="'consumer'" :elementClass="!token ? '.c-trade-now__body' : '.c-suggestion'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
          <TradeNow :outsideContent='true' v-if="!token"></TradeNow>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/consumer/NewIndividualContent.vue'
import TradeNow from '@/components/TradeNow.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'Individual',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    TradeNow,
    LikeSuggestion
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Catch the latest news bytes.jpg'),
          title:"Catch the latest news bytes <br /> <span class='c-page-banner__regular'>on Sparrow’s Blog</span>",
          subtitle: "",
          buttonText:"Find out more >",
          buttonPage:"/library/sparrow-blog",
          themeColor: 'black'
        },
      ]
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Trade Cryptocurrency Options On A Secure Platform')
    this.updateDescriptionMeta('Access cryptocurrency options trading with Sparrow on a simple and secure platform that is managed with the highest compliance and operational standards.')
    this.updateKeywordMeta('Sparrow, sparrow exchange, trading on cryptocurrencies, options trading, buy cryptocurrencies in Singapore, digital currencies, cryptocurrencies in Singapore')
  },
  computed: {
    token:function(){
      return this.$store.getters.token;
    },
  }
}
</script>
