<template>
  <div class="c-page-banner">
    <div class="c-page-banner__container" v-if="bannerData[0].themeColor == 'black'">
      <div class = "c-page-banner__item" v-for="(banner, idx) in bannerData" :key="idx">
        <img :src="banner.img" alt="banner image" class="c-page-banner__image">
        <div class = "c-page-banner__content">
          <h1 class="c-page-banner__title c-page-banner__title_black" :class = "{'c-page-banner__title_two': theme=='two'}" v-if = "banner.title" v-html = "banner.title"></h1>
          <h2 class="c-page-banner__subtitle c-page-banner__subtitle_black" :class = "{'c-page-banner__subtitle_two': theme=='two'}" v-if = "banner.subtitle" v-html = "banner.subtitle"></h2>
          <button class="c-page-banner__button c-page-banner__button_blue" :class = "{'c-page-banner__button_two': theme=='two'}" v-if = "banner.buttonText && banner.buttonPage" @click = "goTo(banner.buttonPage)">{{banner.buttonText}}</button>
        </div>
      </div>
    </div>
    <div class="c-page-banner__container" v-else>
      <div class = "c-page-banner__item" v-for="(banner, idx) in bannerData" :key="idx">
        <img :src="banner.img" alt="banner image" class="c-page-banner__image" :class="{'c-page-banner__imagePeople' : theme=='one'}">
        <div class = "c-page-banner__content">
          <h1 class="c-page-banner__title" :class = "{'c-page-banner__title_two': theme=='two', 'c-page-banner__title_white': theme=='one'}" v-if = "banner.title" v-html = "banner.title"></h1>
          <h2 class="c-page-banner__subtitle" :class = "{'c-page-banner__subtitle_two': theme=='two'}" v-if = "banner.subtitle" v-html = "banner.subtitle"></h2>
          <button class="c-page-banner__button" :class = "{'c-page-banner__button_two': theme=='two'}" v-if = "banner.buttonText && banner.buttonPage" @click = "goTo(banner.buttonPage)">{{banner.buttonText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageBanner',
  props: {
    bannerData: Array,
    theme: {
      type: String,
      default: ''
    },
  },
  data() {
    return {

    }
  },
  methods: {
    goTo(page) {
      if(page.includes("http")) {
        window.open(page)
      } else {
        this.$router.push(page).catch(() => {})
      }
    }
  }
}
</script>
